function queryTabbar() {
  return document.querySelectorAll<HTMLElement>('#tabbar-container');
}

let tabbarHeight = 0;
export function hideTabbar(useDisplay = false) {
  queryTabbar().forEach(i => {
    if (i.style.visibility === 'hidden' || i.style.display === 'none') {
      return;
    }
    if (i.clientHeight !== 0) {
      tabbarHeight = i.clientHeight;
    }
    if (useDisplay) {
      i.style.display = 'none';
    } else {
      i.style.height = '0';
      i.style.visibility = 'hidden';
      i.parentElement!.style.overflow = 'hidden';
    }
  });
}

export function showTabbar(useDisplay = false) {
  queryTabbar().forEach(i => {
    if (
      (!useDisplay &&
        (!i.style.visibility || i.style.visibility === 'visible')) ||
      (useDisplay && i.style.display === 'flex')
    ) {
      return;
    }
    if (useDisplay) {
      i.style.display = 'flex';
    } else {
      i.style.height = `${tabbarHeight}px`;
      i.style.visibility = 'visible';
      i.parentElement!.style.overflow = 'auto';
    }
  });
}
